
import { navigate } from "gatsby";

const IndexPage = () => {
  if (typeof window !== "undefined") {
    navigate("/es/");
  }

  return null;
};

export default IndexPage;
